import React from 'react';
import List from 'antd/lib/list';
import Text from 'antd/lib/typography/Text';

export default function Shortcuts(): JSX.Element {
    const items = [
        { title: 'N', description: 'Repeat the last drawing' },
        { title: 'F', description: 'Go to the next frame' },
        { title: 'D', description: 'Go to the previous frame' },
        { title: 'V', description: 'Go forward 10 frames' },
        { title: 'C', description: 'Go backward 10 frames' },
        { title: 'O', description: 'Stop tracking class for next frames' },
    ];

    return (
        <List bordered header={<Text strong>Shortcuts</Text>}>
            {items.map((item, index) => (
                <List.Item key={index}>
                    <List.Item.Meta
                        avatar={<Text code>{item.title}</Text>}
                        description={item.description}
                    />
                </List.Item>
            ))}
        </List>
    );
}
